<template>
   <div>
      <div :class="{ 'select-style': this.$route.path === `${item.to}` }" class="card-body d-flex flex-row pe-0 ml-5">
         <div class="nav-icon d-inline-block d-flex align-text-top mt-1">
            <component :is="item.tag"></component>
         </div>
         <div class="nav-text">
            <p
               class="card-title"
               :class="{
                  'text-primary': this.$route.path === `${item.to}`,
                  'mt-2': this.$route.path.startsWith('/definition'),
               }"
            >
               {{ item.title }}
            </p>
            <p class="card-text">{{ item.subtitle }}</p>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: 'AmcNav',
   props: {
      item: {
         type: [Array, Object],
         default: () => [],
      },
   },
};
</script>

<style lang="scss" scoped>
.select-style {
   background: #e5efff;
   border-radius: 5px;
}

.card-body {
   &:hover {
      background: #e5efff;
      border-radius: 5px;
   }

   .nav-text {
      display: inline-block;
      margin-left: 10px;
      color: #2e3b52;

      & .card-title {
         font-size: 15px;
         font-weight: 600;
         margin-bottom: 5px;
      }

      & .card-text {
         font-size: 11px;
      }
   }
}
</style>
